/** Styles for About Us Page*/

.aboutus-header {
  position: relative;
}

.aboutus-header {
  height: 100%;
  width: 100vw;
  background-image: url("../assets/Logo/drp.webp");
  opacity: 0.9;
  background-size: cover;
  background-position: center;
  background-color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.aboutus-header-text {
  color: black;
  text-align: center;
  margin: 0 auto;
  max-width: 100%;
  padding-bottom: 20px;
}

.aboutus-header-text h1 {
  font-size: 4rem;
  letter-spacing: 0.4rem;
}

.aboutus-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100vw;
  margin: 0 auto;
  padding: 30px 0px;
}

.aboutus-image {
  flex: 0 0 50%;
  max-width: 50%;
  margin: 20px;
}

.aboutus-image img {
  max-width: 100%;
  height: 100%;
}

.aboutus-text {
  flex: 0 0 50%;
  max-width: 50%;
  margin: 20px;
}

.aboutus-text h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.aboutus-text p {
  line-height: 1.7rem;
  font-size: 16px;
  padding-right: 20px;
  max-width: 500px;
}

/** Mobile Styles for About Us Page */

@media (max-width: 768px) {
  .aboutus-content {
    flex-direction: column;
    padding: 15px;
  }

  .aboutus-image,
  .aboutus-text {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0;
  }

  .aboutus-text h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .aboutus-text p {
    font-size: 14px;
    line-height: 1.5rem;
    padding-right: 0;
    max-width: 100%;
  }

  .aboutus-text h1 {
    font-size: 2rem;
    letter-spacing: 0.1rem;
  }

  .aboutus-header-text {
    color: black;
    text-align: center;
    margin: 0 auto;
    max-width: 90%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    width: 100vw;
    font-size: 2rem;
  }

  .aboutus-header-text h1 {
    font-size: 2rem;
  }
}
